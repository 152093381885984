var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-overlay',{attrs:{"show":_vm.isOnLoad,"variant":"dark","rounded":"","opacity":"0.70","spinner-variant":"primary","spinner-small":""}},[_c('div',{staticClass:"SMcontainer",style:(_vm.estimateBg())},[_c('span',{on:{"click":function($event){_vm.showEditor=!_vm.showEditor}}},[_vm._v(_vm._s(_vm.configModule.config.name))]),(_vm.showEditor)?_c('div',{staticClass:"SMEditor"},[_vm._l((_vm.arr),function(item,index){return (item.isVisible)?_c('div',{key:index,staticClass:"level1"},[(!item.isOnSelect)?_c('div',{staticStyle:{"float":"left"}},[(item.checked)?_c('b-icon',{attrs:{"icon":"check-square-fill"},on:{"click":function($event){item.checked = false;
          _vm.toogleAllChild(item);}}}):_vm._e(),(!item.checked)?_c('b-icon',{attrs:{"icon":"check-square"},on:{"click":function($event){item.checked = true;
          _vm.toogleAllChild(item, true);}}}):_vm._e()],1):_vm._e(),_vm._v(" "+_vm._s(item.title)+" "),_c('div',{staticStyle:{"clear":"both"}}),_vm._l((item.childs),function(itemSub,subIndex){return (item.checked && true && itemSub.isVisible)?_c('div',{key:subIndex,staticClass:"level2"},[(!itemSub.isOnSelect)?_c('div',{staticStyle:{"float":"left"}},[(itemSub.checked)?_c('b-icon',{staticStyle:{"color":"green"},attrs:{"icon":"check-square-fill"},on:{"click":function($event){itemSub.checked = false;
            _vm.toogleAllChild(itemSub);}}}):_vm._e(),(itemSub.checked && itemSub.childs.length>0)?_c('b-icon',{staticStyle:{"color":"blue"},attrs:{"icon":"arrow-down-square-fill"},on:{"click":function($event){itemSub.hideChild = !itemSub.hideChild}}}):_vm._e(),(!itemSub.hideChild)?_c('b-icon',{staticStyle:{"color":"pink"},attrs:{"icon":" kanban-fill"},on:{"click":function($event){return itemSub.childs.map(p => p.showPic = !p.showPic);}}}):_vm._e(),(!itemSub.hideChild && itemSub.childs.filter(x => x.checked === false).length === itemSub.childs.length)?_c('b-icon',{staticStyle:{"color":"yellow"},attrs:{"icon":" dice6-fill"},on:{"click":function($event){return _vm.toogleLowChilds(itemSub);}}}):_vm._e(),(!itemSub.hideChild && itemSub.childs.filter(x => x.checked === true).length === itemSub.childs.length)?_c('b-icon',{staticStyle:{"color":"red"},attrs:{"icon":" dice1-fill"},on:{"click":function($event){return _vm.toogleLowChilds(itemSub, false);}}}):_vm._e(),(!itemSub.checked)?_c('b-icon',{attrs:{"icon":"check-square"},on:{"click":function($event){itemSub.checked = true;
            _vm.toogleAllChild(itemSub, true);
            _vm.toogleLowChilds(itemSub);}}}):_vm._e()],1):_vm._e(),(itemSub.isOnSelect)?_c('div',{staticStyle:{"float":"left"}},[(itemSub.selected)?_c('b-icon',{staticStyle:{"color":"green"},attrs:{"icon":" patch-check-fill"}}):_vm._e(),(!itemSub.selected)?_c('b-icon',{attrs:{"icon":"patch-check"},on:{"click":function($event){_vm.selectIt(itemSub, item);
            itemSub.selected = true;}}}):_vm._e()],1):_vm._e(),_vm._v(" "+_vm._s(itemSub.title)+" "),_c('div',{staticStyle:{"clear":"both"}}),_vm._l((itemSub.childs),function(itemLast,lastIndex){return ((!itemSub.hideChild && (itemSub.checked && true && !itemLast.isOnSelect) || ( itemLast.isOnSelect && itemSub.checked && itemSub.selected && itemLast.isVisible)))?_c('div',{key:lastIndex,class:_vm.estimateStyle(itemLast.isPicture)},[(!itemLast.isOnSelect)?_c('div',{staticStyle:{"float":"left"}},[(itemLast.checked)?_c('b-icon',{staticStyle:{"color":"green"},attrs:{"icon":"check-square-fill"},on:{"click":function($event){itemLast.checked = false}}}):_vm._e(),(!itemLast.checked)?_c('b-icon',{attrs:{"icon":"check-square"},on:{"click":function($event){itemLast.checked = true}}}):_vm._e()],1):_vm._e(),(itemLast.isOnSelect)?_c('div',{staticStyle:{"float":"left"}},[(itemLast.selected)?_c('b-icon',{staticStyle:{"color":"green"},attrs:{"icon":" patch-check-fill"}}):_vm._e(),(!itemLast.selected)?_c('b-icon',{attrs:{"icon":"patch-check"},on:{"click":function($event){_vm.selectIt(itemLast, item)
            itemLast.selected = true;}}}):_vm._e()],1):_vm._e(),_vm._v(" "+_vm._s(itemLast.title)+" "),(itemLast.isPicture)?_c('div',[(!itemLast.isOnSelect && itemLast.showPic)?_c('img',{attrs:{"src":itemLast.pic}}):_vm._e()]):_vm._e(),(itemLast.checked && itemLast.isOverLapable)?_c('div',{staticStyle:{"width":"97%"}},[_c('base-input',{attrs:{"placeholder":"Valeur"},model:{value:(itemLast.overlap),callback:function ($$v) {_vm.$set(itemLast, "overlap", $$v)},expression:"itemLast.overlap"}})],1):_vm._e()]):_vm._e()})],2):_vm._e()})],2):_vm._e()}),(_vm.canSave())?_c('b-button',{staticStyle:{"float":"right"},attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.saveAction()}}},[_vm._v(" Enregistrer ")]):_vm._e()],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }