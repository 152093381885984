const FormData = require('form-data');
import axios from 'axios'
import config from '../config';
import {MODULES} from '../config';

export function getMODULESByID(ID) {
  return getMODULES().filter(e => e.item === ID)
}

export function getMODULESByType(type, univers) {
  var mouduleFounds = getMODULES().filter(e => e.type === type).map(c => {
    const result = c.profile.map(u => {
      if(u.skey === univers) {
        return {config: u, item: c.item};
      }
    }).filter(found => found !== undefined);
    return result;
  }).filter(noconf => noconf && noconf.length > 0 && null !== noconf.config);

  return mouduleFounds.map(e => e[0]);
}

export function getMODULES() {
  return MODULES;
}

export function httpAction(payload) {
  switch (payload.methode) {
    case 'POST':
      postAction(payload);
      break;
    case 'PUT':
      putAction(payload);
      break;
    case 'DELETE':
      deleteAction(payload);
      break;
    case 'GET':
      getAction(payload);
      break;
  }
}

function postAction(payload) {
  axios
    .post(getDomaine() + payload.route, convertData(payload.data), getHeader())
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}

function putAction(payload) {
  const options = getHeader();
  options.params = payload.data?? {};// convertData(payload.data?? {});
  axios
    .put(getDomaine() + payload.route, {}, options)
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}

function deleteAction(payload) {
  axios
    .post(getDomaine() + payload.route, convertData({...payload.data, useMethode: 'DELETE'}), getHeader())
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}

function getAction(payload) {
  const options = getHeader();
  options.params = payload.data?? {};// convertData(payload.data?? {});
  axios
    .get(getDomaine() + payload.route, options)
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}

function getHeader() {
  return {
    headers: {
      hpc: 'ccp_store',
      hus: 'root',
      hpt: 'chorba',
    },
  };
}
function getDomaine(type = 'api') {
  switch(type) {
    case 'api':
      return config.paths.apiDomain;
    case 'cloud':
      return config.paths.cloudDomain;
  }

}

function convertData(payload) {
  const formData = new FormData();
  Object.keys(payload).forEach(key => {
    if(key === "useMethode") {
      formData.append(key, payload[key]);
    } else {
      formData.append('_flg__'+key, payload[key]);
    }
    
  });
  return formData;
}

export const getObjectDiff = (obj1, obj2, compareRef = false) => {
  return Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);

      if (compareRef && obj1[key] !== obj2[key]) {
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(obj2));
}

export function loadFolderContent(payload) {
  const options = {};//getHeader();
  options.params = {'____': payload.folder}?? {};// convertData(payload.data?? {});
  axios
    .get(getDomaine('cloud'), options)
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}

export function uploadFolderContent(payload) {
  axios
    .post(getDomaine('cloud'), payload.data, {})
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}

export function deleteFolderContent(payload) {
  axios
    .post(payload.url, payload.data, {})
    .then((response) => payload.onNext(response))
    .catch((error) => payload.onError(error))
    .finally(() => payload.onComplete());
}
