export default {
  colors: {
    default: "#CCC",
    primary: "#999",
    info: "#1d8cf8",
    danger: "#fd5d93",
    teal: "#00d6b4",
    primaryGradient: [
      "rgba(0, 0, 0, 0.1)",
      "rgba(53, 183, 125, 0)",
      "rgba(119,52,169,0)",
    ],
  },
  paths: {
    apiDomain: "https://api.convertibledefrance.fr/",
    cloudDomain: "https://cloud.convertibledefrance.fr/",
  },
};

export const MODULES = [
  {
    item: "PROD_OPTS",
    name: "SELECT - PRODUIT - Options produit",
    type: "SELECT",
    profile: [
      {
        name: "Options de configuration du produit",
        oneSelect: false,
        selectGrp: true,
        skey: "PRODUCT",
        setRoute: "products/",
        setFlag: "__options",
        allowOverlap: false,
      },
    ],
  },
  {
    item: "PROD_ACHAT",
    name: "SELECT - PRODUIT - Options d`achat",
    type: "SELECT",
    profile: [
      {
        name: "Options d`achat du produit",
        oneSelect: false,
        selectGrp: true,
        skey: "PRODUCT",
        setRoute: "products/",
        setFlag: "__options_buy",
        allowOverlap: false,
      },
    ],
  },
  {
    item: "PROD_CAT",
    name: "SELECT - PRODUIT - Catégories",
    type: "SELECT",
    profile: [
      {
        name: "Catégories du produit",
        oneSelect: false,
        selectGrp: false,
        skey: "PRODUCT",
        setRoute: "products/",
        setFlag: "__categories",
        allowOverlap: false,
      },
    ],
  },
  {
    item: "PROD_TAGS",
    name: "SELECT - PRODUIT - Tags",
    type: "SELECT",
    profile: [
      {
        name: "Tags / Mots clés du produit",
        oneSelect: false,
        selectGrp: false,
        skey: "PRODUCT",
        setRoute: "products/",
        setFlag: "__tags",
        allowOverlap: false,
      },
    ],
  },
  {
    item: "PROD_PLUS",
    name: "SELECT - PRODUIT - Avantages",
    type: "SELECT",
    profile: [
      {
        name: "Avantages du produit",
        oneSelect: false,
        selectGrp: true,
        skey: "PRODUCT",
        setRoute: "products/",
        setFlag: "__plus",
        allowOverlap: false,
      },
    ],
  },
  
  {
    item: "PROD_SPEC_FICHE",
    name: "MODULE - PRODUIT - Spécification par produit",
    type: "MODULE",
    profile: [
      {
        name: "Spécification du produit",
        selectOptionAsModule: false,
        skey: "PRODUCT",
      },
    ],
  },

  {
    item: "PROD_DESC",
    name: "MODULE - PRODUIT - Description",
    type: "MODULE",
    profile: [
      {
        name: "Description du produit",
        selectOptionAsModule: true,
        skey: "PRODUCT",
      },
      {
        name: "Description du produit",
        selectOptionAsModule: true,
        skey: "CATALOGUE",
      },
      {
        name: "Description du produit",
        selectOptionAsModule: true,
        skey: "PAGE",
      },
    ],
  },

  {
    item: "PROD_DETAIL",
    name: "MODULE - PRODUIT - Résumé produit",
    type: "MODULE",
    profile: [
      {
        name: "Résumé du produit",
        selectOptionAsModule: true,
        skey: "PRODUCT",
      },
      
    ],
  },

  {
    item: "PROD_GRID",
    name: "MODULE - CATALOGUE - Grille de produits",
    type: "MODULE",
    profile: [
      {
        name: "Grille de produits",
        selectOptionAsModule: true,
        skey: "PRODUCT",
      },
      {
        name: "Grille de produits",
        selectOptionAsModule: true,
        skey: "CATALOGUE",
      },
      {
        name: "Grille de produits",
        selectOptionAsModule: true,
        skey: "PAGE",
      },
    ],
  },

  {
    item: "PROD_FILTER_MENU",
    name: "MODULE - CATALOGUE - Sous menu de filtrage",
    type: "MODULE",
    profile: [
      {
        name: "Sous menu",
        isFilter: true,
        selectOptionAsModule: false,
        skey: "CATALOGUE",
      },
    ],
  },

  {
    item: "PROD_PARALAX",
    name: "MODULE - COMMUN - Parallax",
    type: "MODULE",
    profile: [
      {
        name: "Bloc Paralax",
        selectOptionAsModule: true,
        skey: "PRODUCT",
      },
      {
        name: "Bloc Paralax",
        selectOptionAsModule: true,
        skey: "PAGE",
      },
    ],
  },

  {
    item: "PROD_DESIGNER",
    name: "MODULE - COMMUN - Designer",
    type: "MODULE",
    profile: [
      {
        name: "Bloc designer",
        selectOptionAsModule: true,
        skey: "PRODUCT",
      },
      {
        name: "Bloc designer",
        skey: "CATALOGUE",
        selectOptionAsModule: true,
      },
      {
        name: "Bloc designer",
        selectOptionAsModule: true,
        skey: "PAGE",
      },
    ],
  },

  {
    item: "POINTS_FORTS",
    name: "MODULE - COMMUN - Points forts",
    type: "MODULE",
    profile: [
      {
        name: "Points forts",
        selectOptionAsModule: false,
        skey: "PRODUCT",
      },
      {
        name: "Points forts",
        skey: "CATALOGUE",
      },
    ],
  },
  {
    item: "SCROLL",
    name: "MODULE - COMMUN - Messages scrollables",
    type: "MODULE",
    profile: [
      {
        name: "Messages scrollables",
        selectOptionAsModule: false,
        skey: "PRODUCT",
      },
      {
        name: "Messages scrollables",
        skey: "CATALOGUE",
      },
    ],
  },
  {
    item: "SCROLL_HOME",
    name: "MODULE - COMMUN - News barre",
    type: "MODULE",
    profile: [
      {
        name: "News barre",
        isFilter: true,
        selectOptionAsModule: false,
        skey: "PAGE",
      },
      {
        name: "News barre",
        isFilter: true,
        selectOptionAsModule: false,
        skey: "CATALOGUE",
      },
    ],
  },

  {
    item: "FAQ",
    name: "MODULE - COMMUN - FAQ",
    type: "MODULE",
    profile: [
      {
        name: "Faq",
        selectOptionAsModule: false,
        skey: "PRODUCT",
      },
      {
        name: "Faq",
        selectOptionAsModule: false,
        skey: "CATALOGUE",
      },
      {
        name: "Faq",
        selectOptionAsModule: false,
        skey: "PAGE",
      },
    ],
  },

  {
    item: "POPUP",
    name: "HTML - COMMUN - POPUP",
    type: "MODULE",
    profile: [
      {
        name: "Popup",
        selectOptionAsModule: true,
        isPopup: true,
        skey: "PRODUCT",
      },
      {
        name: "Popup",
        isPopup: true,
        selectOptionAsModule: true,
        skey: "CATALOGUE",
      },
      {
        name: "Popup",
        isPopup: true,
        selectOptionAsModule: true,
        skey: "PAGE",
      },
    ],
  },
  {
    item: "BLOC_TEXT",
    name: "HTML - COMMUN -  Texte HTML",
    type: "MODULE",
    profile: [
      {
        name: "Texte en HTML",
        selectOptionAsModule: false,
        skey: "PRODUCT",
      },
      {
        name: "Texte en HTML",
        selectOptionAsModule: false,
        skey: "CATALOGUE",
      },
      {
        name: "Texte en HTML",
        selectOptionAsModule: false,
        skey: "PAGE",
      },
    ],
  },
  {
    item: "BLOC_DIAPO",
    name: "HTML - COMMUN -  Bloc carrousel",
    type: "MODULE",
    profile: [
      {
        name: "Bloc carrousel",
        selectOptionAsModule: false,
        skey: "PRODUCT",
      },
      {
        name: "Bloc carrousel",
        selectOptionAsModule: false,
        skey: "CATALOGUE",
      },
      {
        name: "Bloc carrousel",
        selectOptionAsModule: false,
        skey: "PAGE",
      },
    ],
  },
  {
    item: "BLOC_IMGS",
    name: "HTML - COMMUN -  Bloc images cliquables",
    type: "MODULE",
    profile: [
      {
        name: "Bloc images cliquables",
        selectOptionAsModule: false,
        skey: "PAGE",
      },
    ],
  },
  {
    item: "BLOC_CONTACT",
    name: "HTML - COMMUN -  Bloc section",
    type: "MODULE",
    profile: [
      {
        name: "Bloc de section",
        selectOptionAsModule: false,
        skey: "PRODUCT",
      },
      {
        name: "Bloc de section",
        selectOptionAsModule: false,
        skey: "CATALOGUE",
      },
      {
        name: "Bloc de section",
        selectOptionAsModule: false,
        skey: "PAGE",
      },
    ],
  },
  {
    item: "BLOC_ADRESSE",
    name: "HTML - COMMUN -  Bloc adresses",
    type: "MODULE",
    profile: [
      {
        name: "Bloc d'adresses",
        selectOptionAsModule: false,
        skey: "PRODUCT",
      },
      {
        name: "Bloc d'adresses",
        selectOptionAsModule: false,
        skey: "CATALOGUE",
      },
      {
        name: "Bloc d'adresses",
        selectOptionAsModule: false,
        skey: "PAGE",
      },
    ],
  },
  {
    item: "BLOC_VIDEO",
    name: "HTML - COMMUN -  Bloc vidéo",
    type: "MODULE",
    profile: [
      {
        name: "Bloc vidéo",
        selectOptionAsModule: true,
        skey: "PRODUCT",
      },
      {
        name: "Bloc vidéo",
        selectOptionAsModule: true,
        skey: "CATALOGUE",
      },

      {
        name: "Bloc video",
        selectOptionAsModule: true,
        skey: "PAGE",
      },
    ],
  },

  {
    item: "PROD_SPEC",
    name: "TECHNIQUE - PRODUIT - Template Spécification",
  },
  {
    item: "NAV_MENU",
    name: "TECHNIQUE - NAVIGATION - Menu",
    type: "MODULE",
    profile: [
      {
        name: "Menu",
        isMenu: true,
        selectOptionAsModule: false,
        skey: "SITE",
      },
    ],
  },
];
