/*
 =========================================================
 * Vue Black Dashboard - v1.1.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2023 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import App from "./App";
import router from "./router/ccpRouter";

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n";
import { getObjectDiff as differ } from "./helpers";
import VueCookies from 'vue-cookies'
import "./registerServiceWorker";
import {
  BootstrapVue,
  OverlayPlugin,
  TabsPlugin,
  SpinnerPlugin,
  IconsPlugin,
  ModalPlugin,
  AspectPlugin,
} from "bootstrap-vue";
import wysiwyg from "vue-wysiwyg";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import {
  httpAction,
  uploadFolderContent,
  loadFolderContent,
  deleteFolderContent,
  getMODULES,
  getMODULESByType,
  getMODULESByID,
} from "./helpers";

Vue.use(wysiwyg, { minHeight: "500px" }); // config is optional. more below
Vue.use(IconsPlugin);
Vue.use(SpinnerPlugin);
Vue.use(OverlayPlugin);
Vue.use(TabsPlugin);
Vue.use(BlackDashboard);
Vue.use(BootstrapVue);
Vue.use(ModalPlugin);
Vue.use(AspectPlugin);
Vue.use(VueCookies);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueLodash, { name: "custom", lodash: lodash });
Vue.prototype.__ = {
  httpAction,
  differ,
  uploadFolderContent,
  loadFolderContent,
  deleteFolderContent,
  getMODULES,
  getMODULESByType,
  getMODULESByID,
};

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
