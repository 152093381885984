<template>
  <div class="PMcontainer">
    <b-overlay
      :show="isOnLoad"
      variant="dark"
      rounded
      opacity="0.70"
      spinner-variant="primary"
      spinner-small
    >      
      <div v-if="showEditor" class="Editor">
      <b>Liste des produits</b>
      <b-button
        variant="danger"
        @click=" showEditor = false"
        size="sm"
        style="float: right"
        v-if="showEditor"
      >
        Fermer
      </b-button> 
      <category-dropdwon :config="configCatDD" v-on="{updateByCategorieDropDown: onUpdateByCategorieDropDown}"></category-dropdwon>


      <b-form-input v-model="filterStr" placeholder="Chercher"></b-form-input>

      <div class="inner">
        <div class="item" v-for="(prd, index) in filterPrdList()"> 
          <b-icon
            v-if="prd.selected "
            icon="check-square-fill"
            @click="
              prd.selected = false;
              removeItem(prd);
            "
          ></b-icon>
          <b-icon
            v-if="!prd.selected && config.length > config.prodList.length"
            icon="check-square"
            @click="
              prd.selected = true;
              appendItem(prd, true);
            "
          ></b-icon>
          {{ prd.title}}
        </div>

      </div>
      </div>
      <b-button
        variant="success"
        @click=" showEditor = true"
        size="sm"
        style="float: right"
        v-if="!showEditor"
      >
        Charger
      </b-button>

      <div style="clear: both"></div>
      <div class="level1" v-for="(item, index) in config.prodList">
        <b-icon
          v-if="item.act"
          icon="check-square-fill"
          @click="item.act = false;"
          style="color: green"
        ></b-icon>
        <b-icon
          v-if="!item.act"
          icon="check-square"
          @click="item.act = true;"
        ></b-icon>

        
        <b-icon
          v-if="index > 0"
          icon="caret-up-fill"
          @click="mouve(item,-1)"
        ></b-icon>

        <b-icon
          v-if="index < config.prodList.length -1"
          icon="caret-down-fill"
          @click="mouve(item)"
        ></b-icon>

        <br>
        <span>{{ item.title }}</span>
        <b-img
                    :src="item.pic"
                    fluid
                    style="width: 100%"
                  ></b-img>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import { computed } from "vue";

export default {
  name: "prod-manager",
  components: {},

  props: {
    config: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      configCatDD: {class: "form-control"},
      isOnLoad: false,
      dataToAdd: '',
      data: [],
      prodList: [],
      catList: [],
      showEditor: false,
      catIdFilter: '-1',
      filterStr: ''
    };
  },
  methods: {
    onUpdateByCategorieDropDown(id) {
      this.catIdFilter = id.data;
    },
    
    filterPrdList () {
        return this.prodList.filter(e => {
          var cnd1 = (this.catIdFilter != '-1') ? e.__categories_filter.includes(this.catIdFilter) : true;
          var cnd2 = (this.filterStr != '') ? e.title.includes(this.filterStr) : true;
          return cnd1 && cnd2
        })
    },

    appendItem(item) {
      item.act = true;
      item.order = this.config.prodList.length -1;
      this.config.prodList.push(item)
    },

    removeItem(item) {
      this.config.prodList = this.config.prodList.filter(e => e.id != item.id)
    },

    setStyle(w) {
      return 'float: left; width:'+w;
    },

    mouve(itm, sens = 1) {
      itm.order += sens;
      this.sortModules();
    },

    sortModules() {
      this.config.prodList = this.config.prodList.sort((a, b) => a.order - b.order);
    },

    
    formatJsonEntry(arr) {
      
      return arr.map(e => {
        var __categories = JSON.parse(e.__categories);
        var ret = [];
        if(__categories === null) {
          return {...e, __categories_filter: ret};
        }
        
        __categories.map(x => {
          ret.push(x.id);
          x.value.map(s => ret.push(s.id))
        })
        return {...e, __categories_filter: ret};
      });
    },

    loadData() {
      let $onNext = (response) => {
        this.prodList = this.formatJsonEntry(response.data.result).map((e) => {
         
          return {
          title: e.__title,
          pic: e.__vignette,
          id: e.__id,
          selected: this.config.prodList.includes(e.__id),
          order: 0,
          act: false,
          __categories_filter: e.__categories_filter,
          }
        });
        this.prodList.sort(function (a, b) {
          if (a.title < b.title )  {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        });
        
        this.init();
        this.isOnLoad = false;
      };
      const $onError = () => {};
      const $onComplete = () => {
        this.isOnLoad = false;
      };

      this.isOnLoad = true;
      this.__.httpAction({
        methode: 'GET',
        route: 'products/',
        data: null,
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
  },

  mounted() {
    this.loadData()
    //this.init();
  },

  watch: {
    showEditor: function (newVal, oldVal) {
        var c = [].concat(this.config.prodList);
        this.prodList.map(e => e.selected = c.map(l => l.id).includes(e.id))
    },

    filterStr: function (newVal, oldVal) {
        if(newVal === 'SET1') {
          var arr = [365,408,414,354,397,405,409,411,336,400,410,326,412,413,355,368,398,402,404,328,401,382,387,407,345,390,386,380,379,384,381,377,366,349,7,372,320,311,244,234,301,11,334,323,338,347,352,324,101,369,342,361,240,340,313,362,292,322,341,371,250,158,286,68,337,370,243]
          //console.log("$$$$$ ", arr)
          arr.map(e => {
            var o = this.prodList.filter(i => {
              console.log("===> ",i)
              return i.id == e.toString()
            });
            console.log(o,e.toString())
            this.appendItem(o[0],true)
          })
          this.filterStr.value = '';
        }  
    }
  },

};
</script>
<style lang="scss" scoped>
.PMcontainer {
  background: #121821;
  color: #fff;
  width: 100%;
  float: left;
  padding: 2%;
  font-size: 13px;
  margin-top: 10px;
  border-radius: 10px;
}

.level1 {
  background: #142537;
  width: 32%;
  margin-left:1%;
  float: left;
  padding: 2%;
  font-size: 13px;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 10px;
}
.level1 .b-icon {
  font-size: 13px;
  margin-right: 10px;
}

.Editor {
  border-radius: 10px;
    padding: 10px;
    color: black;
    background: white;
    position: fixed;
    right: 10px;
    z-index: 10000;
    top: 10px;
    max-height: 97%;
    min-height: 97%;
    max-width: 20%;
    display: block;
}

.Editor .inner {
  margin-top: 10px;
  clear: both
}
.Editor .item {
  font-size:10px !important;
}
.form-group {

}
.form-control  {
  background: #2b3553 !important;
  color: white !important;
  margin-top: 5px;
}

.custom-select {
  background: #2b3553 !important;
}

.custom-select option {
  background: #2b3553 !important;
}
</style>
