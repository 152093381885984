<template>
  <div class="TMcontainer">
    <b-overlay
      :show="isOnLoad"
      variant="dark"
      rounded
      opacity="0.70"
      spinner-variant="primary"
      spinner-small
    >
      <b-form-select
        v-if="modules.length > 0"
        v-model="ModuleToAdd"
        :options="[{ value: '-1', text: '...' }].concat(modules)"
        @change="onSelectModule()"
        class="form-control"
        style="
          float: left;
          width: 24%;
          height: 35px !important;
          margin-right: 1%;
        "
      ></b-form-select>

      <b-form-select
        v-if="categories.length > 0"
        v-model="CatToAdd"
        :options="[{ value: '-1', text: '...' }].concat(categories)"
        @change="onSelectCategorie()"
        class="form-control"
        style="
          float: left;
          width: 24%;
          height: 35px !important;
          margin-right: 1%;
        "
      ></b-form-select>

      <b-form-select
        v-if="options.length > 0"
        v-model="OptToAdd"
        :options="[{ value: '-1', text: '...' }].concat(options)"
        @change="onSelectOption()"
        class="form-control"
        style="
          float: left;
          width: 24%;
          height: 35px !important;
          margin-right: 1%;
        "
      ></b-form-select>

      <b-button
        v-if="allowSave()"
        variant="success"
        @click="AddAction()"
        size="sm"
        style="float: right"
      >
        Ajouter
      </b-button>

      <b-button
        v-if="!allowSave()"
        variant="success"
        @click="saveAction()"
        size="sm"
        style="float: right"
      >
        Enregistrer
      </b-button>

      <div style="clear: both"></div>
      <div  v-for="(item, index) in data" :class="getClass(item)">
        <b-icon
          v-if="item.optConf && item.optConf.isGrid"
          icon="gear-fill"
          @click="gridID = (gridID === -1) ? index : -1"
          style="color: blue"
        ></b-icon>

        <b-icon
          v-if="item.act"
          icon="check-square-fill"
          @click="item.act = false"
          style="color: green"
        ></b-icon>
        <b-icon
          v-if="!item.act"
          icon="check-square"
          @click="item.act = true"
        ></b-icon>

        <b-icon
          icon="trash-fill"
          @click="remouve(item)"
          style="color: red"

        ></b-icon>

        <b-icon
          v-if="index > 0 && gridID == -1"
          icon="caret-up-fill"
          @click="mouve(item,-1)"
        ></b-icon>

        <b-icon
          v-if="index < data.length -1 && gridID == -1"
          icon="caret-down-fill"
          @click="mouve(item)"
        ></b-icon>


        <div v-if="item.optConf && item.optConf.isGrid" style="float : right; width: 80%">
        <b>Grille de produits</b>
        <prod-manager :config="item.optConf" v-if="gridID === index"></prod-manager>
        </div>
        
        <div v-else style="float : right; width: 80%">
          <span>{{ item.module.text }}</span>
          <span> - {{ item.cat.text }}</span>
          <span v-if="item.opt.id !== -1"> - {{ item.opt.text }}</span>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import { computed } from "vue";

export default {
  name: "template-manager",
  components: {},

  props: {
    configModule: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isOnLoad: false,
      ModuleToAdd: -1,
      CatToAdd: -1,
      OptToAdd: -1,
      modules: [],
      categories: [],
      options: [],
      moduleConf: {},
      data: [],
      gridID: -1,
    };
  },
  methods: {
    onSelectModule() {
      this.CatToAdd = this.OptToAdd = -1;
      this.categories = this.options = [];
      if (this.ModuleToAdd === -1) {
        return;
      }
      this.moduleConf = this.configModule.modules.filter(
        (m) => m.item === this.ModuleToAdd
      )[0].config;
      this.loadData();
    },

    getClass(itm) {
      var cls ='level1';
      if(itm.module.isFilter) {
        cls+=' filter';
      }
      if(itm.module.isPopup) {
        cls+=' isPopup';
      }
      return cls;
    },

    getModuleName() {
      return this.modules.filter((m) => m.value === this.ModuleToAdd)[0].text;
    },

    getModuleIsFilter() {
      return this.modules.filter((m) => m.value === this.ModuleToAdd)[0].isFilter;
    },
    
    getModuleIsPopup() {
      return this.modules.filter((m) => m.value === this.ModuleToAdd)[0].isPopup;
    },

    getCatName() {
      if (this.CatToAdd === -1) return "";
      return this.categories.filter((m) => m.value === this.CatToAdd)[0].text;
    },

    getOptName() {
      if (this.OptToAdd === -1) return "";
      return this.options.filter((m) => m.value === this.OptToAdd)[0].text;
    },

    checkOptConfig() {
      if (this.OptToAdd === -1) return null;
      var conf = this.options.filter((m) => m.value === this.OptToAdd)[0].config;
      if(conf == null)
        return {};
      var checkConfig = JSON.parse(conf || '');
      var optionDetail = this.options.filter((m) => m.value === this.OptToAdd)[0];

      if(checkConfig !== null) {
        if(checkConfig?.isGrid) {
          return {
            isGrid: true,
            length: checkConfig?.limit || -1,
            useBig: checkConfig?.useBig,
            display: checkConfig?.display || null,
            titleData:  (checkConfig?.useTitle) ? {title: optionDetail.text, style: checkConfig?.useTitleStyle} : null,
            prodList: []
          };
        }
      }
      return {}
    },

    onSelectCategorie() {
      this.options = [];
      this.OptToAdd = -1;
      if (this.CatToAdd === -1) {
        return;
      }
      if (this.moduleConf.selectOptionAsModule) this.loadOptData();
    },

    onSelectOption() {},

    allowSave() {
      if (this.moduleConf.selectOptionAsModule) return this.OptToAdd !== -1;
      return this.CatToAdd !== -1;
    },

    AddAction() {
      this.data.push({
        order: this.getModuleIsFilter() || this.getModuleIsPopup() ? -1000 : this.data.length + 1,
        act: true,
        module: { id: this.ModuleToAdd, text: this.getModuleName(), isFilter: this.getModuleIsFilter(), isPopup: this.getModuleIsPopup() },
        cat: { id: this.CatToAdd, text: this.getCatName() },
        opt: { id: this.OptToAdd, text: this.getOptName() },
        optConf: this.checkOptConfig(),
      });
      this.sortModules();
      this.ModuleToAdd = -1;
      this.onSelectModule();
    },

    mouve(itm, sens = 1) {
      itm.order += sens;
      this.sortModules();
    },

    remouve(itm) {
      this.$bvModal.msgBoxConfirm('Veuillez confirmer la suppression.', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Confirmer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false
      })
        .then(value => {
          if(value) {
            this.data = this.data.filter(e => e.order !== itm.order);
          }
        })
    },

    sortModules() {
      this.data = this.data.sort((a, b) => a.order - b.order);
    },

    loadData() {
      let $onNext = (response) => {
        this.categories = response.data.result.map((e) => {
          return {
            text: e.__title,
            value: e.__id,
            type: "cat",
          };
        });
      };

      const $onError = () => {};
      const $onComplete = () => {
        this.isOnLoad = false;
      };

      this.isOnLoad = true;
      this.__.httpAction({
        methode: "GET",
        route: "optionsgrp/",
        data: { __useLoop: 0, __useCnd: "0__SPL__" + this.ModuleToAdd },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadOptData() {
      let $onNext = (response) => {
        this.options = response.data.result.map((e) => {
          return {
            text: e.__title,
            value: e.__id,
            type: "opt",
            config: (e.__config),
          };
        });
      };
      const $onError = () => {};
      const $onComplete = () => {
        this.isOnLoad = false;
      };

      this.isOnLoad = true;
      this.__.httpAction({
        methode: "GET",
        route: "options/",
        data: { __useCnd: "2__SPL__" + this.CatToAdd },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    saveAction() {
      let $onNext = (response) => {};
      const $onError = () => {};
      const $onComplete = () => {
        this.isOnLoad = false;
      };
      this.isOnLoad = true;
      var diff = {};
      diff[this.configModule.warpConfig.flagDep[0]] = this.configModule.dependency[0];
      diff[this.configModule.warpConfig.flagDep[1]] = JSON.stringify(this.data);
      this.updateAction({ $onNext, $onError, $onComplete, data: diff });
    },

    updateAction(conf) {
      this.__.httpAction({
        methode: "POST",
        route: this.configModule.warpConfig.route,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },

    init() {
      this.data = [];
      if(this.configModule.dependency === undefined)
        return
      if (this.configModule.dependency[1]) {
        this.data = JSON.parse(this.configModule.dependency[1]);
      }
      
      this.modules = this.configModule.modules.map((e) => {
        return { text: e.config.name, value: e.item , isFilter: e.config.isFilter, isPopup: e.config.isPopup};
      });
    }
  },

  mounted() {
    this.init();
  },

  watch: {
    configModule: function () {
      this.init();
    },
  },

  computed: {},
};
</script>
<style lang="scss" scoped>
.TMcontainer {
  background: #0c0e10;
  width: 100%;
  float: left;
  padding: 2%;
  font-size: 18px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.level1 {
  background: #142537;
  width: 100%;
  float: left;
  padding: 2%;
  font-size: 13px;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 10px;
}
.filter {
  background: #1f2900;
}
.isPopup {
  background: #2d1e02;
}
.level1 .b-icon {
  font-size: 13px;
  margin-right: 10px;
}
</style>
