<template>
  <div class="DMcontainer">
    <b-overlay
      :show="isOnLoad"
      variant="dark"
      rounded
      opacity="0.70"
      spinner-variant="primary"
      spinner-small
    >
      {{ title }}
      <div v-if="showEditor">
        <div v-for="(itm, index) in dataFlg">
          <base-input
            v-if="itm.type === 'text'"
            :style="setStyle(itm._w)"
            :placeholder="itm.title"
            v-model="dataToAdd[index]"
            >
          </base-input>

          <b-form-select
            v-if="itm.type === 'list'"
            v-model="dataToAdd[index]"
            :options="itm.value"
            class="form-control"
            :style="setStyle(itm._w)"
          ></b-form-select>
        </div>
      </div>
      

      <b-button
        v-if="allowAdd()"
        variant="success"
        @click="showEditor = true"
        size="sm"
        style="float: right"
      >
        Ajouter
      </b-button>

      <b-button
        v-if="allowSave()"
        variant="success"
        @click="showEditor = false; addAction()"
        size="sm"
        style="float: right"
      >
        Enregistrer
      </b-button>

      <b-button
        v-if="allowUpd()"
        variant="success"
        @click="showEditor = false; updAction()"
        size="sm"
        style="float: right"
      >
        Valider
      </b-button>

      <div style="clear: both"></div>
      <div class="level1" v-for="(item, index) in data">
        <b-icon
          v-if="item.act"
          icon="check-square-fill"
          @click="item.act = false; saveAction()"
          style="color: green"
        ></b-icon>
        <b-icon
          v-if="!item.act"
          icon="check-square"
          @click="data.map(e=> e.act = false); item.act = true; saveAction()"
        ></b-icon>

        <b-icon
          icon="trash-fill"
          @click="remouve(item)"
          style="color: red"

        ></b-icon>

        <b-icon
          icon="gear-fill"
          @click="edit(item, index)"

        ></b-icon>
        
        <b-icon
          v-if="index > 0"
          icon="caret-up-fill"
          @click="mouve(item,-1)"
        ></b-icon>

        <b-icon
          v-if="index < data.length -1"
          icon="caret-down-fill"
          @click="mouve(item)"
        ></b-icon>

        <span> {{ item.data.join(', ')}}</span>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import { computed } from "vue";

export default {
  name: "data-manager",
  components: {},

  props: {
    configModule: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isOnLoad: false,
      title: '',
      dataToAdd: [],
      dataFlg: [],
      showEditor: false,
      editIdx: -1,
      moduleConf: {},
      data: [],
    };
  },
  methods: {

    allowAdd() {
      return !this.showEditor && this.editIdx == -1
    },

    allowSave() {
      return this.showEditor && this.editIdx == -1
    },

    allowUpd() {
      return this.showEditor && this.editIdx !== -1
    },

    addAction() {
      
      this.data.push({
        order: this.data.length + 1,
        act: false,
        data: this.dataToAdd,
      });
      this.dataToAdd =  this.dataToAdd.map(e => '')
      this.sortModules();
    },

    setStyle(w) {
      return 'float: left; width:'+w;
    },

    mouve(itm, sens = 1) {
      itm.order += sens;
      this.sortModules();
    },

    edit (item, i) {
      this.editIdx = i;
      this.dataToAdd = item.data;
      this.showEditor = true;
    },

    updAction() {
      this.dataToAdd =  this.dataToAdd.map(e => '');
      this.editIdx = -1;
      this.saveAction()
    },

    remouve(itm) {
      this.$bvModal.msgBoxConfirm('Veuillez confirmer la suppression.', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Confirmer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false
      })
        .then(value => {
          if(value) {
            this.data = this.data.filter(e => e.order !== itm.order);
          }
        })
    },

    sortModules() {
      this.data = this.data.sort((a, b) => a.order - b.order);
      
    },

    saveAction() {
      let $onNext = (response) => {};
      const $onError = () => {};
      const $onComplete = () => {
        this.isOnLoad = false;
      };
      this.isOnLoad = true;
      var diff = {};
      diff[this.configModule.warpConfig.flagDep[0]] = this.configModule.dependency[0];
      diff[this.configModule.warpConfig.flagDep[1]] = JSON.stringify(this.data);
      this.$emit("updateByDataManager", JSON.stringify(this.data));
      this.updateAction({ $onNext, $onError, $onComplete, data: diff });
    },



    updateAction(conf) {
      this.__.httpAction({
        methode: "POST",
        route: this.configModule.warpConfig.route,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },

    init() {
      this.data = [];
      if(this.configModule.dependency === undefined)
        return
      if (this.configModule?.dependency[1]) {
        this.data = JSON.parse(this.configModule.dependency[1]);
      }
      this.title = this.configModule.data.title;
      this.dataFlg = this.configModule.data.flg;
      this.dataToAdd = this.dataFlg.map(e =>  '')
    }
  },

  mounted() {
    this.init();
  },

  watch: {
    configModule: function () {
      this.init();
    },
    data: function () {
      this.saveAction();
    },
  },

  computed: {},
};
</script>
<style lang="scss" scoped>
.DMcontainer {
  background: #3c2641;
  color: #fff;
  width: 100%;
  float: left;
  padding: 2%;
  font-size: 13px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.level1 {
  background: #142537;
  width: 100%;
  float: left;
  padding: 2%;
  font-size: 13px;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 10px;
}
.level1 .b-icon {
  font-size: 13px;
  margin-right: 10px;
}
</style>
