<template>
  <div>
    <div >
      <b-button variant="outline-primary" @click="onSelect()" v-if="__payload.multiSelection && selectedItems.length>0" style="float: right;">
          <b-icon icon="check-square"></b-icon>
          Selectionner
        </b-button>

        <base-input
          placeholder="Chercher"
          style="float: left;"
          v-model="filterStr"
        >
        </base-input>
      </div>
            
      

      <div style="clear: both;"></div>
      <div v-for="n in 10" style="float: left; margin: 0.1%; width: 9.8%;">
      <b-card v-for="(item, index) in purgeList(n)" :key="index"
      no-body
      :img-src=" item.url "
      img-alt="Image"
      img-top
      
      v-if="filterStr == '' || item.name.includes(filterStr) && filterStr != ''"
      style="margin-bottom: 3%;"
    >
      <b-card-text class="small text-muted" @click="dispatchOnCliqueImg(item.url)" style="padding:3px ; text-align: center;">{{ item.name }}</b-card-text>
      <template #footer>
        {{ Math.round(item.size/1024) }} kb
      <base-button
        icon
        type="danger"
        size="sm"
        style="margin-left: 4px"
        @click="dispatchDelete(item.delete_url, index)"
      >
        <b-icon icon="trash-fill"></b-icon>
      </base-button>

      <base-button
        icon
        type="info"
        size="sm"
        style="margin-left: 4px"
        v-if="__payload.multiSelection && selectedItems.includes(item.url)"
        @click="dispatchUnselect(item.url)"
      >
        <b-icon icon="check-square-fill"></b-icon>
      </base-button>

      <base-button
        icon
        size="sm"
        type="info"
        style="margin-left: 4px; background-color: grey; background-image: none;"
        v-if="__payload.multiSelection && !selectedItems.includes(item.url)"
        @click="dispatchselect(item.url)"
      >
        <b-icon icon="check-square"></b-icon>
      </base-button>
    </template>
    </b-card>
  </div>
    </div>
</template>
<script>
import { BaseButton } from '@/components';
export default {
  props: {
    __list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    __payload: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      selectedItems: [],
      filterStr: ""
    };
  },
  methods: {
    purgeList(idx) {
      return this.__list.filter((e, index) => {
        return ((((index+1) % 10) == idx) || (((index+1) % 10) == 0 && idx == 10));
      });
    },
    dispatchselect(url) {
      this.selectedItems.push(url)
    },
    dispatchUnselect(url) {
      this.selectedItems = this.selectedItems.filter(e => e !== url)
    },

    dispatchOnCliqueImg(url) {
      if(!this.$props.__payload.multiSelection) {
        this.selectedItems[0] = url
        this.onSelect()
      } else {
        
      }
    },
    onSelect() {
      this.$props.__payload.onFireCB(this.selectedItems);
    },
    onDeleteAction(path, idx) {
      this.$props.__payload.onDelete(path, idx);
    },
    dispatchDelete(path, idx) {
      this.$bvModal.msgBoxConfirm('Veuillez confirmer la suppression.', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Confirmer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false
      })
        .then(value => {
          if(value) {
            this.onDeleteAction(path, idx);
          }
        })
    },
  },
};
</script>
<style></style>
