<template>
 <b-overlay
    :show="__isOnLoad"
    variant="dark"
    rounded
    opacity="0.70"
    spinner-variant="primary"
    spinner-small
  >
      <!--UPLOAD-->
      <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
        <div class="dropbox">
          <input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
            accept="image/*" class="input-file">
            <p v-if="isInitial">
              Cliquez ici ou bien glisser les photos a envoyer dans cette zone!
            </p>
            <p v-if="isSaving">
              
              Envoi en cours {{ fileCount }} fichiers...
            </p>
        </div>
      </form>
      </b-overlay>
</template>

<script>
  // swap as you need
  //import { upload } from './file-upload.fake.service'; // fake service
  // import { upload } from './file-upload.service';   // real service
  //import { wait } from './utils';

  const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

  export default {
    name: 'uploader',
    props: {
      __payload: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    data() {
      return {
        __isOnLoad: false,
        fileCount: 0,
        uploadedFiles: [],
        uploadError: null,
        currentStatus: null,
        uploadFieldName: 'files[]'
      }
    },
    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      }
    },
    methods: {
      reset() {
        // reset form to initial state
        this.__isOnLoad = false;
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = [];
        this.uploadError = null;
      },
      save(formData) {
        // upload data to the server
        this.currentStatus = STATUS_SAVING;

        this.__.uploadFolderContent({
          onNext: (data)=>{
            this.uploadedFiles = [].concat(data);
            this.currentStatus = STATUS_SUCCESS;
            this.$props.__payload.onUploadSuccess(data);
            this.reset();
          },
          onComplete: (data) => {},
          onError: () => {
            this.uploadError ='Une erreur est produite';
            this.currentStatus = STATUS_FAILED;
            this.$props.__payload.onUploadFail();
            this.reset();
          },
          data: formData,
        })
      },
      filesChange(fieldName, fileList) {
        this.__isOnLoad = true;
        // handle file changes
        const formData = new FormData();
        formData.append('____', this.$props.__payload.folder);
        if (!fileList.length) return;
        const a = []
        // append the files to FormData
        Array
          .from(Array(fileList.length).keys())
          .map(x => {
            formData.append(fieldName, fileList[x], fileList[x].name);
          });
        //formData.append(fieldName, a);
        // save it
        this.save(formData);
      }
    },
    mounted() {
      this.reset();
    },
  }

</script>

<style lang="scss">
  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background:#d9d9d9;
    color: dimgray;
    padding: 13px 13px;
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background:#d6d6d6;/* when mouse over to the drop zone, change color */
  }
  
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>