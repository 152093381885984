<template>
  <b-overlay
    :show="isOnLoad"
    variant="dark"
    rounded
    opacity="0.70"
    spinner-variant="primary"
    spinner-small
  >
    <div class="level1" v-for="(item, index) in arr" :key="index">
      <b-icon
        v-if="item.checked"
        icon="check-square-fill"
        @click="
          item.checked = false;
          toogleAllChild(item);
        "
      ></b-icon>
      <b-icon
        v-if="!item.checked"
        icon="check-square"
        @click="
          item.checked = true;
          toogleAllChild(item, true);
        "
      ></b-icon>
      {{ item.title }}

      <div
        class="level2"
        v-for="(itemSub, subIndex) in item.childs"
        :key="subIndex"
        v-if="item.checked"
      >
        <b-icon
          v-if="itemSub.checked"
          icon="check-square-fill"
          @click="
            itemSub.checked = false;
            toogleAllChild(itemSub);
          "
        ></b-icon>
        <b-icon
          v-if="!itemSub.checked"
          icon="check-square"
          @click="
            itemSub.checked = true;
            toogleAllChild(itemSub, true);
          "
        ></b-icon>
        {{ itemSub.title }}

        <div
          class="level3"
          v-for="(itemLast, lastIndex) in itemSub.childs"
          :key="lastIndex"
          v-if="itemSub.checked"
        >
          <b-icon
            v-if="itemLast.checked"
            icon="check-square-fill"
            @click="itemLast.checked = false"
          ></b-icon>
          <b-icon
            v-if="!itemLast.checked"
            icon="check-square"
            @click="itemLast.checked = true"
          ></b-icon>
          {{ itemLast.title }}
          <div style="width: 97%" v-if="itemLast.checked">
            <base-input placeholder="Valeur" v-model="itemLast.overlap">
            </base-input>
          </div>
        </div>
      </div>
    </div>

    <b-button
      variant="success"
      @click="saveAction()"
      size="sm"
      style="float: right"
    >
      Enregistrer
    </b-button>
  </b-overlay>
</template>
<script>
import { computed } from "vue";

export default {
  name: "spec-editor",
  components: {},

  props: {
    warp: {
      type: String,
      default: "",
    },
    parentID: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isOnLoad: false,
      conf: [],
      catItems: [],
      optItems: [],
      overlapedItems: [],
      arr: [],
    };
  },
  methods: {
    saveAction() {
      let $onNext = (response) => {};
      const $onError = () => {};
      const $onComplete = () => {
        this.isOnLoad = false;
      };
      this.isOnLoad = true;
      var conf = [].concat(this.arr).filter(e =>  e.checked === true);

      var res = conf.map((e) => {
          return {
            id: e.ID,
            type: 'cat',
            value: e.childs
              .filter((c) => c.checked)
              .map((c) => {
                return {
                  id: c.ID,
                  type: 'cat',
                  value: c.childs
                    .filter((l) => l.checked)
                    .map((l) => {
                      var ret =  { id: l.ID,  type: 'opt',}
                      if(l.value !== l.overlap)
                        ret.overlap = l.overlap.split('"').join("`")                     
                      return  ret;
                    }),
                };
              }),
          };
        });
        
      var diff = {
        __id: this.parentID,
        __config: JSON.stringify(res)
      } 
      this.updateAction({ $onNext, $onError, $onComplete, data: diff });
    },

    updateAction(conf) {
      this.__.httpAction({
        methode: "POST",
        route: "optionsgrp/",
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },

    toogleAllChild(itm, state = false) {
      itm.childs.map((e) => {
        e.checked = state;
        if (e.childs.length > 0) this.toogleAllChild(e, state);
        return e;
      });
    },
    // recursive 0 sans, 1 by child sub, 2 by subID to get options list, 3 last level no need child and should be overlapable
    setDataAsArray(arr, container, recursive = 0) {
      for (var i = 0; i < arr.length; i++) {
        const opt = arr[i];
        var typeConf = (recursive === 3) ? 'opt':'cat';
        var isChecked = false;
        var overlap = opt.__desc;
        switch(recursive) {
          case 1:
          case 2:
            isChecked = this.catItems.includes(opt.__id);
          break;
          case 3:
          isChecked = this.optItems.includes(opt.__id);
          var haveOverLap =  this.overlapedItems.filter(e => e.id === opt.__id);
          if(haveOverLap.length>0)
            overlap = haveOverLap[0].valueOverlap
          break;
        }
     
        container.push({
          title: opt.__title,
          ID: opt.__id,
          checked: isChecked,
          childs: [],
          overlap: recursive === 3,
          value: opt.__desc,
          overlap: overlap,
        });
        if (recursive === 1) {
          this.setDataAsArray(opt.__sub, container[i].childs, 2);
        }
        if (recursive === 2) {
          this.loadSubData(opt.__id, container[i].childs);
        }
      }
    },

    loadData() {
      let $onNext = (response) => {
        this.setDataAsArray(response.data.result, this.arr, 1);
      };

      const $onError = () => {};
      const $onComplete = () => {
        this.isOnLoad = false;
      };

      this.isOnLoad = true;
      this.__.httpAction({
        methode: "GET",
        route: "optionsgrp/",
        data: { __useLoop: 0, __useCnd: "0__SPL__PROD_SPEC" },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadSubData(parentID, container) {
      let $onNext = (response) => {
        this.setDataAsArray(response.data.result, container, 3);
      };

      const $onError = () => {};
      const $onComplete = () => {
        this.isOnLoad = false;
      };

      this.isOnLoad = true;
      this.__.httpAction({
        methode: "GET",
        route: "options/",
        data: { __useCnd: "2__SPL__" + parentID },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    checkFroOptionStaus(arr) {
      arr.map(e => {
        if(e.type === 'cat') {
          this.catItems.push(e.id)
        }
        if(e.type === 'opt') {
          this.optItems.push(e.id)
          if(e.overlap) {
            this.overlapedItems.push({id: e.id, valueOverlap: e.overlap})
          }
        }
        if(e.value && e.value.length> 0) {
          this.checkFroOptionStaus(e.value)
        }
      })
    }
  },

  mounted() {
    if(this.warp) {
      this.conf = JSON.parse(this.warp);
      this.checkFroOptionStaus(this.conf)
    }
      
    this.loadData();
  },

  computed: {},
};
</script>
<style lang="scss" scoped>
.level1 {
  font-size: 18px;
}
.level1 .b-icon {
  font-size: 18px;
  margin-right: 10px;
}

.level2 {
  margin-left: 20px;
  font-size: 14px;
}
.level2 .b-icon {
  font-size: 14px;
  margin-right: 10px;
}

.level3 {
  margin-left: 40px;
  font-size: 11px;
}
.level3 .b-icon {
  font-size: 11px;
  margin-right: 10px;
}
.form-control {
  height: 20px !important;
  padding: 5px !important;
  font-size: 11px !important;
}
</style>
