<template>
    <b-overlay
      :show="isOnLoad"
      variant="dark"
      rounded
      opacity="0.70"
      spinner-variant="primary"
      spinner-small
      style="width: 100%"
    >
      <b-form-select v-if="!isOnLoad" v-model="catIdFilter" :options="optionsList"  :class="config.class" :style="config.style"></b-form-select>
    </b-overlay>
</template>
<script>
import { computed } from "vue";

export default {
  name: "category-dropdwon",
  components: {},

  props: {
    config: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isOnLoad: true,
      catIdFilter: -1,
      optionsList: [{text: '...', value: '-1'}],
      grpList: [],
      catList: [],
      onGoingReq: []
    };
  },
  methods: {
    init() {
      this.loadData();
    },

    prepareList() {
      this.grpList.map(g => {
        this.optionsList.push({
          text: g.title,
          value: g.id,
        })
        this.catList.filter(c => c.parent === g.id).map(e => {
          this.optionsList.push({
            text: " +  "+e.title,
            value: e.id,
          })
        })
      })
    },

    loadData() {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        this.grpList = response.data.result.map(e => {
          this.loadSubData(e.__id)
          return {
            id: e.__id,
            title: e.__title,
            isGrp: true,
          }
        });
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.isOnLoad = true;
      this.__.httpAction({
        methode: "GET",
        route: "optionsgrp/",
        data: { __useLoop: 0, __useCnd: "0__SPL__PROD_CAT" },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadSubData(parentID) {
      this.onGoingReq.push({});
      let $onNext = (response) => {
        response.data.result.map(e => {
          this.catList.push({
            parent: parentID,
            id: e.__id,
            title: e.__title,
          }) 
        });
      };

      const $onError = () => {};
      const $onComplete = () => {
        if (this.onGoingReq.length > 0) this.onGoingReq.pop();
      };

      this.isOnLoad = true;
      this.__.httpAction({
        methode: "GET",
        route: "options/",
        data: { __useCnd: "2__SPL__" + parentID },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
  },

  mounted() {
    this.init();
  },

  watch: {
    onGoingReq: function () {
      this.isOnLoad = this.onGoingReq.length > 0;
    },
    isOnLoad: function () {
      if(this.isOnLoad === false) {
        this.prepareList()
      }
    },
    catIdFilter: function () {
      this.$emit("updateByCategorieDropDown", {
        data: this.catIdFilter
      });
    }
  },

  computed: {},
};
</script>
<style lang="scss" scoped>
.form-group {

}
.form-control  {
  background: #2b3553 !important;
  color: white !important;
  margin-top: 5px;
}

.custom-select {
  background: #2b3553 !important;
}

.custom-select option {
  background: #2b3553 !important;
}
</style>
