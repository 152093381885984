import Vue from 'vue';
import Router from 'vue-router';
import DashboardLayout from '../layout/starter/SampleLayout.vue';
import CheckScreenLayout from "@/layout/checkScreen/checkScreen.vue";
const Produits = () => import(/* webpackChunkName: "common" */ "@/ccp_pages/Produits/index.vue");
const Options = () => import(/* webpackChunkName: "common" */ "@/ccp_pages/Options/index.vue");
const Display = () => import(/* webpackChunkName: "common" */ "@/ccp_pages/Display/index.vue");


export const fullRouter = 
    {
      path: '/',
      name: 'Accueil',
      redirect: '/sites',
      component: DashboardLayout,
      children: [
        {
          path: 'produits',
          name: 'Gestion des produits',
          components: { default: Produits }
        },
        {
          path: 'options',
          name: 'Gestion des options',
          components: { default: Options }
        },
        {
          path: '/boutiques',
          name: 'Gestion des boutiques',
          components: { default: Display },
          props: { default: () => ({ DISPLAY: 'SHOP' }) }
        },
        {
          path: '/sites',
          name: 'Gestion des sites',
          components: { default: Display },
          props: { default: () => ({ DISPLAY: 'SITE' }) }
        },
        {
          path: '/pages',
          name: 'Gestion des pages',
          components: { default: Display },
          props: { default: () => ({ DISPLAY: 'PAGE' }) }
        }
      ]
    };


const routes = [
  {
    path: "/",
    component: CheckScreenLayout,
  },
  { path: "*", redirect: '/'},
];

Vue.use(Router);

export default new Router({routes: routes});