<template>
    <div :class="type">
      <b-icon icon="trash-fill" @click="$parent.$parent.dispatchDelete(index)"  v-if="!(index == idxOnEdition) && tab.includes('del')" style="color: red"></b-icon>
      <b-icon icon="gear-fill" @click="$parent.$parent.dispatchEdit(index)"  v-if="!(index == idxOnEdition) && tab.includes('edit')"></b-icon>
      <b-icon icon="eye-slash-fill" @click="$parent.$parent.dispatchDesactive(index)"  v-if="!(index == idxOnEdition) && act == 1 && tab.includes('act')"></b-icon>
      <b-icon icon="eye-fill" @click="$parent.$parent.dispatchActive(index)"  v-if="!(index == idxOnEdition) && act == 0 && tab.includes('act')" style="color: #7f9f0a"></b-icon>
    </div>
</template>
<script>
export default {
  name: "Crtl",
  props: {
    index: {
      type: Number,
      default: ()=> {
        return -1
      }
    },
    idxOnEdition: {
      type: Number,
      default: ()=> {
        return -1
      }
    },
    act: {
      type: Number,
      default: ()=> {
        return 0
      }
    },
    type: {
      type: String,
      default: ()=> {
        return 'crtlV'
      }
    },
    tab: {
      type: Array,
      default: ()=> {
        return ["act",'edit','del']
      }
    },
  },
  mounted() {
  }
};
</script>
<style>
.crtlH {
  padding: 5px;
  border-radius: 10px;
  opacity: 1;
  background: white;
  float: right;
}
.crtlV {
  width:35px;
  position: absolute;
  border-radius: 10px;
  opacity: 0.8;
  background: white;
}
.b-icon.bi {
    display: inline-block;
    overflow: visible;
    vertical-align: -0.15em;
    margin-left: 3px;
    margin-top: 3px;
    font-size: x-large;
    cursor: pointer;
}
.b-icon.bi:hover { opacity:0.5}
</style>
