<template>
  <b-overlay
    :show="$data.__isOnLoad"
    variant="dark"
    rounded
    opacity="0.70"
    spinner-variant="primary"
    spinner-small
  >
    <div style="width: 100%;" >
      <div class="text-right" v-if="!showUploader">
        <b-button variant="outline-primary" @click="showUploaderAction()">
          <b-icon icon="clipboard-plus"></b-icon>
          Uploader
        </b-button>
      </div>
      <Uploader :__payload="__uploadPayload" v-if="showUploader"></Uploader>
    </div>
    <div>
      <List
        :__list="folderContent"
        :__payload="__folderPayload"
      ></List>
    </div>
</b-overlay>
</template>
<script>
import Uploader from './Uploader';
import List from './List';
export default {
  name: "galerie-manager",
  components: {
    Uploader,
    List,
  },
  props: {
    __payload: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      __isOnLoad: false,
      __uploadPayload: {},
      __folderPayload: {},
      showUploader: false,
      folderContent: [],
    };
  },
  computed: {
    listStyle() {
      return this.onEditionId !== null ? 'col-md-4' : 'col-md-12';
    },
  },
  methods: {
    loadFolder() {
      this.$data.__isOnLoad = true;
      this.__folderPayload = this.$props.__payload;
      this.__folderPayload.onSelect = (attr) => this.onUploadOK(attr);
      this.__folderPayload.onDelete = (url, idx) => this.deleteFile(url, idx);
      this.__.loadFolderContent({
          onNext: (data)=>{
            this.folderContent = [].concat(data.data);
            this.$data.__isOnLoad = false;
          },
          onComplete: (data) => {},
          onError: () => {
            this.$data.__isOnLoad = false;
          },
          folder: this.$props.__payload.folder,
        })
    },
    deleteFile(url, idx) {
      this.$data.__isOnLoad = true;
      const formData = new FormData();
      formData.append('____', this.$props.__payload.folder);
      formData.append('_method', 'DELETE');
      this.__.deleteFolderContent({
          onNext: (data)=>{
            this.folderContent.splice(idx,1);
            this.$data.__isOnLoad = false;
          },
          onComplete: (data) => {},
          onError: () => {
            this.$data.__isOnLoad = false;
          },
          url: url,
          data: formData,
        })
    },
    showUploaderAction() {
      this.__uploadPayload = this.$props.__payload;
      this.showUploader = true;
      this.__uploadPayload.onUploadSuccess = (data) => this.onUploadOK(data);
      this.__uploadPayload.onUploadFail = () => this.onUploadKO();
    },
    onUploadOK(data) {
      this.showUploader = false;
      this.folderContent = this.folderContent.concat(data.data);
    },
    onUploadKO() {
      this.showUploader = false;
    },

  },
  watch: {  
    __payload: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadFolder()
  },
};
</script>
<style></style>
