<template>
  <div class="row" style="margin-left: 0px; margin-right:0px">
    <div class="col-md-6"  style="margin: 0px auto; margin-top: 15%;">
      <b-overlay
      :show="inLoad"
      variant="dark"
      rounded
      opacity="0.70"
      spinner-variant="primary"
      spinner-small
    >
    <card type="user">
    <p class="card-text">
    </p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <a href="#">
        <b-icon icon="shield-lock-fill" scale="4"></b-icon>
        <h2 class="title" style="margin-top:40px; margin-bottom: 0px;">Authentification</h2>
        <h5 class="title">Veuillez remplir ces champs</h5>
      </a>
    </div>
    <p>

    <div class="row" >
    <div class="col-md-6">
      <b-input-group size="m" class="mb-2">
      <b-input-group-prepend is-text>
        <b-icon icon="person-fill"></b-icon>
      </b-input-group-prepend>
      <b-form-input type="text" v-model="loginFiled" placeholder="Login | email"></b-form-input>
    </b-input-group>
    </div>

    <div class="col-md-6">
    <b-input-group size="m" class="mb-2">
      <b-input-group-prepend is-text>
        <b-icon icon="gear-fill"></b-icon>
      </b-input-group-prepend>
      <b-form-input v-model="pwdFiled" type="password" placeholder="mot de passe"></b-form-input>
    </b-input-group>
    </div></div>
    </p>

    <div slot="footer" class="button-container">
      <b-collapse id="collapse-4" v-model="loginError" class="mt-2">
      Erreur d'authentification, veuillez vérifier vos codes
    </b-collapse>

      <base-button size="sm" :show="!inLoad" @click="login()">
        Login
      </base-button>
     
    </div>
  </card>
</b-overlay>
  </div>
  </div>
</template>
<script>
import Vue from "vue";
import {fullRouter} from "@/router/ccpRouter";
export default {
  components: {
  },
  data() {
    return {
      loginFiled: '',
      pwdFiled: '',
      loginError: false,
      inLoad : false,
      requireAuth : false,
    };
  },
  methods: {
    checkAndGo(){
      const admin = Vue.$cookies.get("user");
      if(admin) {
        this.addRouterAndGo(admin)
      } else {
        this.requireAuth = true;
      }
    },

    addRouterAndGo(admin) {
      if(admin.isRoot === 1) {
        this.$router.addRoute(fullRouter);
        this.$router.push('/sites')
      } else {
        this.$router.addRoute(fullRouter);
        this.$router.push('/sites')
      }
    },

    login(){
      let $onNext = (response) => {
        if(response.data.result.length === 0) {
          this.loginError = true;
          this.inLoad = false;
          return;
        }
        Vue.$cookies.set('user',response.data.result[0]);
        setTimeout(()=> {
          this.inLoad = false;
          this.addRouterAndGo(response.data.result[0])
        }, 300)
        
      };

      let $onError = (error) => {
        this.inLoad = false;
      };

      let $onComplete = () => {
        this.inLoad = false;
      };

      this.inLoad = true;
      this.loginError = false;
      this.__.httpAction({
        methode: 'GET',
        route: 'admin/',
        data: { __useCnd: '1__SPL__'+this.loginFiled+'__SPL__'+this.pwdFiled},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
  },
  mounted() {    
    //Vue.$cookies.remove("user")
    this.checkAndGo()
  }
};
</script>
