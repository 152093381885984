<template>
  <b-overlay
    :show="isOnLoad"
    variant="dark"
    rounded
    opacity="0.70"
    spinner-variant="primary"
    spinner-small
  >

  <b-modal 
    v-model="showAddModal" 
    scrollable 
    :hide-footer= true
    :hide-header= true
    dialog-class = "addModal"
    >
    <div class="row">

    <div class="col-md-12 pr-md-1">
      <b-form-select
      v-model="selectedIdx"
      :options="listDropDown"
      class="mb-3"
      value-field="idx"
      text-field="html"
    ></b-form-select>

    </div>

    <div class="text-right">
    <b-button variant="outline-primary" @click="confirmAdd()">
      <b-icon icon="clipboard-plus"></b-icon>
      Valider
    </b-button>
  </div>
    </div>
    </b-modal>

  <b-card bg-variant="secondary" text-variant="white" v-if="combMode">
    <div class="text-right">
      <b-button variant="danger" @click="dispatchCancel()"> Fermer </b-button>
      <b-button variant="outline-primary" @click="dispatchAdd()" v-if="onEditionId === null">
        <b-icon icon="clipboard-plus"></b-icon> Ajouter 
      </b-button>
      <b-button variant="success" @click="saveAction()" v-if="onEditionId !== null">
         Enregistrer 
      </b-button>
    </div>

    <b-card
      v-for="(comb, index) in combArray" :key="index"
      border-variant="primary"
      header-tag="header"
      header-bg-variant="purple"
      header-text-variant="white"
      :no-body= true
    >
    <template #header>
        
        <div class="text-right" style="float: right;" v-if="onEditionId === null">
          <b-button
            size="sm"
            @click="dispatchEdit(index)"
            style="margin-left: 4px"
          >
          <b-icon icon="gear-fill"></b-icon> Editer
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            style="margin-left: 4px"
            @click="dispatchDelete(index)"
          >
            Supprimer
          </b-button>
        </div>
        
        <div class="col-md-12 pr-md-1" style="float: left ;" v-if="onEditionId == comb.__id">
          <div class="col-md-8 pr-md-1" style="float: left ;">
            <base-input
              placeholder="__option_label"
              v-model="onEditionItem.__option_label"
            >
            </base-input>
          </div>

          <div class="col-md-2 pr-md-1" style="float: left ;">
            <b-form-select v-model="onEditionItem.__price_impact" class="form-control">
              <b-form-select-option value="0" style="color: #000;">Gratuite</b-form-select-option>
              <b-form-select-option value="1" style="color: #000;">Variant</b-form-select-option>
              <b-form-select-option value="2" style="color: #000;">Extra</b-form-select-option>
              <b-form-select-option value="3" style="color: #000;">Pourcentage</b-form-select-option>
            </b-form-select>
          </div>

          <div class="col-md-2 pr-md-1" style="float: left ;">
            <base-input
              placeholder="Coût"
              v-model="onEditionItem.__price"
            >
            </base-input>
          </div>
        </div>

        <div class="col-md-12 pr-md-1" style="float: left ; width: 60%;" v-if="onEditionId !== comb.__id">
          <h4 style="float: left ; margin-bottom: 0px;">{{ comb.__option_label }}</h4><br>
          <h5 style="float: left ; clear: both;"> {{ comb.__price }} {{ comb.__price_impact == "3" ? "%" : (comb.__price_impact == "0") ? '' : "€" }}</h5>
        </div>
    </template>
    </b-card>
  </b-card>


  <b-card bg-variant="default" text-variant="white" v-if="!combMode">
    
    <b-icon icon="aspect-ratio-fill" @click="toggleShowHide()" style="
      position: absolute;
      float: right;
      right: 10px;
      z-index: 10;
      width:30px;
      height: 30px;
    "></b-icon>

    <b-icon v-if="extended" icon="ui-checks" @click="toggleSelect()" style="
      position: absolute;
      float: right;
      right: 50px;
      z-index: 10;
      width:30px;
      height: 30px;
    "></b-icon>

<b-icon v-if="extended" icon="currency-euro" @click="combManager()" style="
      position: absolute;
      float: right;
      right: 90px;
      z-index: 10;
      width:30px;
      height: 30px;
    "></b-icon>

    <b-form-group
    :label="grpName"
    v-slot="{ grpId }"
    style="margin-bottom: 0px;"
    >
      <b-form-checkbox-group v-if="extended"
        v-model="parentItem[_model]"
        :options="listParsed"
        :aria-describedby="grpId"
        switches
        size="sm"
        stacked
      ></b-form-checkbox-group>
    </b-form-group>
  </b-card>
  </b-overlay>
</template>
<script>
import { computed } from 'vue';

  export default {
    name: 'option-selector',
    components: {
      
    },
    
    props: {
      grpName: {
        type: String,
        default: '',
      },
      grpId: {
        type: String,
        default: '',
      },
      list: {
        type: Array,
        default: () => [],
      },
      _model: {
        type: String,
        default: '',
      },
      parentItem: {
        type: Object,
        default: () => {},
      }
    },
    data() {
      return {
        isOnLoad: false,
        extended: false,
        combMode: false,
        parentItem: {},
        combArray: [],
        listDropDown: [],
        showAddModal: false,
        selectedIdx: -1,
        thisID:[],
        listParsed: [],
        onEditionItem: {},
        onEditionId: null,
        onEditionIdx: -1,
      }
    },
    methods: {
      dispatchEdit(idx) {
        this.onEditionId = this.combArray[idx].__id;
        this.onEditionIdx = idx;
        this.onEditionItem = this._.omit(this.combArray[idx]);
      },
      dispatchCancelEdit() {
        this.onEditionId = null;
        this.onEditionIdx = -1;
        this.onEditionItem = {};
      },
      dispatchCancel() {
        this.dispatchCancelEdit()
        this.combMode =  false;
      },
      dispatchAdd() {
        this.showAddModal =  true;
      },

      dispatchDelete(idx) {
        this.$bvModal.msgBoxConfirm('Veuillez confirmer la suppression.', {
          title: 'Confirmation',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Confirmer',
          cancelTitle: 'Annuler',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: false
        })
        .then(value => {
          if(value) {
            this.deleteAction(idx);
          }
        })
    },

      confirmAdd() {
        this.showAddModal =  false;
        this.addAction([this.parentItem.__id, this.$props.list[this.selectedIdx].__id, this.$props.list[this.selectedIdx].__title])
      },

      deleteAction(idx) {
        this.isOnLoad = true;
        let $onNext = (response) => {
          if (response.data.result == 'OK') {
            this.combArray.splice(idx, 1);
            this.updateData()
          }
        };

        const $onError = () => {};
        const $onComplete = () => {
          this.isOnLoad = false;
        };

        let data = this._.pick(this.combArray[idx], ['__id']);
        this.__.httpAction({
          methode: 'DELETE',
          route: 'comb/',
          data: data,
          onNext: $onNext,
          onError: $onError,
          onComplete: $onComplete,
        });
      },

      saveAction() {
        let $onNext = (response) => {
          this.dispatchCancelEdit() 
          this.loadCombData();
        };
        const $onError = () => {};
        const $onComplete = () => {
          this.isOnLoad = false;
        };
        this.isOnLoad = true;
  
        let diff = this.__.differ(
          this.combArray[this.onEditionIdx],
          this.onEditionItem
        );
        diff.push('__id');

        let data = this._.pick(this.onEditionItem, diff);
        this.updateAction({ $onNext, $onError, $onComplete, data });
      },
      
      updateAction(conf) {
      this.__.httpAction({
        methode: 'POST',
        route: 'comb/',
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },
      loadCombData() {
        let $onNext = (response) => {
          this.combArray = response.data.result;
          this.updateData();
        };

        const $onError = () => {};
        const $onComplete = () => {
          this.isOnLoad = false;
        };

        this.isOnLoad = true;
        this.__.httpAction({
          methode: 'GET',
          route: 'comb/',
          data: { __useCnd: '2__SPL__'+this.parentItem.__id+'__SPL__'+this.thisID.toString() },
          onNext: $onNext,
          onError: $onError,
          onComplete: $onComplete,
        });
      },

      addAction(objToAdd) {
        this.isOnLoad = true;
        let $onNext = (response) => {
          this.loadCombData();
        };

        let $onError = (error) => {
          this.isOnLoad = false;
        };

        let $onComplete = () => {
          this.isOnLoad = false;
        };
        
        this.__.httpAction({
          methode: 'PUT',
          route: 'comb/',
          data: {__useIns:  '0__SPL__'+objToAdd.toString().split(',').join('__SPL__')},
          onNext: $onNext,
          onError: $onError,
          onComplete: $onComplete,
        });
     },
      
      formatPriceByOption(e) {
        let text = e.__title ? e.__title : 'Sans titre';
        let price ='';
        if (e.__price) {
          switch (e.__price_impact) {
            case '1':
              price += ' <i style="color:#42b883">(' + e.__price + ' €)</i>';
              break;
            case '2':
              price += ' <i style="color:#42b883">( +' + e.__price + ' €)</i>';
              break;
            case '3':
              price += ' <i style="color:#42b883">( +' + e.__price + ' %)</i>';
              break;
          }
        }
        let comb = this.combArray.filter( c => c.__option_id == e.__id);

        if(comb.length>0) {
          comb = comb[0];
          price = '<u style="color:red" >'+price+'</u>';
          if (comb.__price) {
          switch (comb.__price_impact) {
            case '1':
              price += ' <b style="color:#ff8d72">(' + comb.__price + ' €)</b>';
              break;
            case '2':
              price += ' <b style="color:#ff8d72">( +' + comb.__price + ' €)</b>';
              break;
            case '3':
              price += ' <b style="color:#ff8d72">( +' + comb.__price + ' %)</b>';
              break;
          }
        }
        }

        let img = '<img src="' + e.__url + '" style="max-width:10%"/> ' + text+price;
        if (e.__type) {
          return img;
        }
        return text+price;
      },

      combManager() {
        this.combMode =  !this.combMode;
      },
      toggleShowHide() {
        this.extended =  !this.extended;
      },
      toggleSelect() {
        let parentL = this.$props.parentItem[this.$props._model];
        let thisL = this.listParsed.map(e => e.value);
        let alreadyPushed = thisL.filter(e => parentL.includes(e));
        if(alreadyPushed.length > 0) {
          this.$props.parentItem[this.$props._model] = parentL.filter(e => {
            return !thisL.includes(e)
          })
        } else {
          thisL.map(e => parentL.push(e))
        }
      },

      updateData() {
        this.thisID = this.$props.list.map(e => e.__id);
        this.listDropDown = this.$props.list.map((e, index) => {return {html: e.__title, idx: index}});
        this.parentItem = this.$props.parentItem;
        this.listParsed = this.$props.list.map((e) => {
            return { html: this.formatPriceByOption(e), value: e.__id };
          })
      }
    },
    mounted() {
      this.updateData()
      this.loadCombData();
    },
    computed: {
      varPath: function() {
      return this.$props.parentItem[this.$props._model];
      },
    }
  }
</script>
