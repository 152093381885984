<template>
    <b-overlay
    :show="isOnLoad"
    variant="dark"
    rounded
    opacity="0.70"
    spinner-variant="primary"
    spinner-small
  >
  <div class="SMcontainer" :style="estimateBg()">
    <span @click="showEditor=!showEditor">{{ configModule.config.name }}</span>

    <div class="SMEditor" v-if="showEditor">
    <div class="level1" v-for="(item, index) in arr" :key="index" v-if="item.isVisible">
      <div style="float: left" v-if="!item.isOnSelect">
      <b-icon
        v-if="item.checked"
        icon="check-square-fill"
        @click="
          item.checked = false;
          toogleAllChild(item);
        "
      ></b-icon>
      <b-icon
        v-if="!item.checked"
        icon="check-square"
        @click="
          item.checked = true;
          toogleAllChild(item, true);
        "
      ></b-icon>
      </div>
      {{ item.title }}
      <div style="clear: both"></div>
      <div
        class="level2"
        v-for="(itemSub, subIndex) in item.childs"
        :key="subIndex"
        v-if="item.checked && true && itemSub.isVisible"
      >
        
        <div style="float: left" v-if="!itemSub.isOnSelect">
          <b-icon
          v-if="itemSub.checked"
          icon="check-square-fill"
          style="color: green"
          @click="
            itemSub.checked = false;
            toogleAllChild(itemSub);
          "
        ></b-icon>

        <b-icon
          v-if="itemSub.checked && itemSub.childs.length>0"
          style="color: blue"
          icon="arrow-down-square-fill"
          @click="
            itemSub.hideChild = !itemSub.hideChild
          "
        ></b-icon>
        
       
        <b-icon
          v-if="!itemSub.hideChild"
          style="color: pink"
          icon=" kanban-fill"
          @click="
           itemSub.childs.map(p => p.showPic = !p.showPic);
          "
        ></b-icon>

        <b-icon
          v-if="!itemSub.hideChild && itemSub.childs.filter(x => x.checked === false).length === itemSub.childs.length"
          style="color: yellow"
          icon=" dice6-fill"
          @click="
           toogleLowChilds(itemSub);
          "
        ></b-icon>

        <b-icon
          v-if="!itemSub.hideChild && itemSub.childs.filter(x => x.checked === true).length === itemSub.childs.length"
          style="color: red"
          icon=" dice1-fill"
          @click="
            toogleLowChilds(itemSub, false);
          "
        ></b-icon>

       
        <b-icon
          v-if="!itemSub.checked"
          icon="check-square"
          @click="
            itemSub.checked = true;
            toogleAllChild(itemSub, true);
            toogleLowChilds(itemSub);
          "
        ></b-icon>
        </div>

        <div style="float: left" v-if="itemSub.isOnSelect">
        <b-icon
          v-if="itemSub.selected"
          style="color: green"
          icon=" patch-check-fill"
        ></b-icon>
        <b-icon
          v-if="!itemSub.selected"
          icon="patch-check"
          @click="
            selectIt(itemSub, item);
            itemSub.selected = true;
          "
        ></b-icon>
        </div>
        {{ itemSub.title }}
        <div style="clear: both"></div>
        <div
          v-for="(itemLast, lastIndex) in itemSub.childs"
          :key="lastIndex"
          v-if="(!itemSub.hideChild && (itemSub.checked && true && !itemLast.isOnSelect) || ( itemLast.isOnSelect && itemSub.checked && itemSub.selected && itemLast.isVisible))"
          :class="estimateStyle(itemLast.isPicture)"
        >
          <div style="float: left" v-if="!itemLast.isOnSelect">
          <b-icon
            v-if="itemLast.checked"
            style="color: green"
            icon="check-square-fill"
            @click="itemLast.checked = false"
          ></b-icon>
          <b-icon
            v-if="!itemLast.checked"
            icon="check-square"
            @click="itemLast.checked = true"
          ></b-icon>
          </div>

      <div style="float: left" v-if="itemLast.isOnSelect">
        <b-icon
          v-if="itemLast.selected"
          icon=" patch-check-fill"
          style="color: green"
        ></b-icon>
        <b-icon
          v-if="!itemLast.selected"
          icon="patch-check"
          @click="
            selectIt(itemLast, item)
            itemLast.selected = true;
          "
        ></b-icon>
        </div>

          {{ itemLast.title }}
          <div v-if="itemLast.isPicture">
          <img :src="itemLast.pic" v-if="!itemLast.isOnSelect && itemLast.showPic"/>
          </div>
          <div style="width: 97%" v-if="itemLast.checked && itemLast.isOverLapable">
            <base-input placeholder="Valeur" v-model="itemLast.overlap">
            </base-input>
          </div>
        </div>
      </div>
    </div>

    <b-button
      v-if="canSave()"
      variant="success"
      @click="saveAction()"
      size="sm"
      style="float: right"
    >
      Enregistrer
    </b-button>
  </div>
 

</div>
</b-overlay>
</template>
<script>
import { computed } from "vue";

export default {
  name: "select-manager",
  components: {},
  
  props: {
    configModule: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isOnLoad: false,
      showEditor: false,
      conf: [],
      catItems: [],
      optItems: [],
      overlapedItems: [],
      oneSelectItems: [],
      arr: [],
      readyToSave: false,
    };
  },
  methods: {
    estimateBg() {
      return (this.configModule.config.oneSelect) ? 'background:#fff':'';
    },

    estimateStyle(isPic) {
      return (!isPic) ? 'level3' : 'level3 picture';
    },

    saveAction() {
      let $onNext = (response) => {};
      const $onError = () => {};
      const $onComplete = () => {
        this.isOnLoad = false;
      };
      this.isOnLoad = true;
      var res = this.getSelectedTreeToSave();
        
      var diff = {
        __id: this.configModule.dependency[0],
      }
      diff[this.configModule.config.setFlag] = JSON.stringify(res);
      this.updateAction({ $onNext, $onError, $onComplete, data: diff });
    },
    
    updateAction(conf) {
      this.__.httpAction({
        methode: "POST",
        route: this.configModule.config.setRoute,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },

    getSelectedTreeToSave() {
      var conf = [].concat(this.arr).filter(e =>  e.checked === true && e.isVisible === true);
      var res = conf.map((e) => {
          return {
            id: e.ID,
            type: e.type,
            value: e.childs
              .filter((c) => c.checked && c.isVisible)
              .map((c) => {
                return {
                  id: c.ID,
                  type: c.type,
                  value: c.childs
                    .filter((l) => l.checked && l.isVisible)
                    .map((l) => {
                      var ret =  { id: l.ID,  type: l.type,}
                      if(l.value !== l.overlap)
                        ret.overlap = l.overlap
                      return  ret;
                    }),
                };
              }),
          };
        });
        return res;
    },

    selectIt(item, parent) {
      var findPrev = this.oneSelectItems.filter(e => e.id === parent.ID)
      if(findPrev.length > 0) {
        var prevItem = findPrev[0]
        if(prevItem.value !== -1) {
          this.arr
          .filter(e => e.ID === parent.ID)
          .map(e => {
            
            if(item.type === 'cat') {
              e.childs.map(c => {
                c.selected = false

              })
            }
            
            if(item.type === 'opt') {

              e.childs.map(c => {
                if(c.type === 'opt')
                  c.selected = false
                if(c.type === 'cat')
                  c.childs.map(m => m.selected = false) 
              })
            }
          })
        }
        
        if(item.type === 'cat') {
          prevItem.value = { cat: item.ID}
        }
        if(item.type === 'opt') {
          if(prevItem.value === -1)
            prevItem.value = { opt: item.ID}
          else
          prevItem.value = { ...prevItem.value, opt: item.ID}
        }
      }
    },

    canSave() {
      if(this.configModule.config.oneSelect) {
        this.readyToSave = this.oneSelectItems.filter(e => e.value === -1).length === 0;
        return false
      }
      this.readyToSave = true;
      return true
    },

    toogleLowChilds(itm, state = true) {
      itm.childs.map((e) => {
        e.checked = state;
        e.isVisible = state;
        return e;
      });
    },

    toogleAllChild(itm, state = false) {
      itm.childs.map((e) => {
        e.checked = false;
        e.isVisible = true;
        if (e.childs.length > 0) this.toogleAllChild(e, false);
        return e;
      });
    },
    // recursive 0 sans, 1 by child sub, 2 by subID to get options list, 3 last level no need child and should be overlapable
    setDataAsArray(arr, container, recursive = 0, isPicture=false, typeConf = 'cat', parentID = -1) {
      if(this.configModule.config.oneSelect && arr.length == 0 && parentID !== -1) {
       this.arr
       .filter(e => {
        return e.ID === parentID
       })
       .map(e => {
        e.isVisible = false;
      })
      }

      for (var i = 0; i < arr.length; i++) {
        const opt = arr[i];
        //var typeConf = (recursive === 3) ? 'opt':'cat';
        
        var isChecked = false;
        var isSelected = false;
        var overlap = opt.__desc;
        switch(recursive) {
          case 1:
          case 2:
            isChecked = this.catItems.includes(opt.__id);
            if(recursive === 1 && this.configModule.config.oneSelect && isChecked && this.oneSelectItems.filter(f => f.id === opt.__id).length === 0) {
              this.oneSelectItems.push({
                id: opt.__id,
                title: opt.__title,
                value: -1
              })
            }
          break;
          case 3:
          isChecked = this.optItems.includes(opt.__id);
          var haveOverLap =  this.overlapedItems.filter(e => e.id === opt.__id);
          if(haveOverLap.length>0)
            overlap = haveOverLap[0].valueOverlap

          break;
        }
        var isVisible = (o) => {
          if(this.configModule.config.oneSelect) {
            if(typeConf === 'opt')
              return this.optItems.includes(opt.__id);
            if(typeConf === 'cat')
              return this.catItems.includes(opt.__id);
            return false
          }
          return true;
        }
        var toPush = {
          isVisible: isVisible(opt),
          isOnSelect: (this.configModule.config.oneSelect && typeConf === 'opt') || (this.configModule.config.oneSelect && typeConf === 'cat' && opt.__parent_id !== -1),
          selected: false,
          title: opt.__title,
          ID: opt.__id,
          checked: isChecked,
          type: typeConf,
          hideChild: recursive > 1,
          showPic: false,
          childs: [],
          isOverLapable: recursive === 3 && this.configModule.config.allowOverlap,
          value: opt.__desc,
          overlap: overlap,
          isPicture: isPicture,
        };
        if(isPicture) {
          toPush.pic = opt.__url;
        }
        container.push(toPush);
        if (recursive === 1) {
          if(opt.__sub.length === 0)
            this.loadSubData(opt.__id, container[i].childs);
          else 
            this.setDataAsArray(opt.__sub, container[i].childs, 2);
        }
        if (recursive === 2) {
          if(opt.__type =="0")
            this.loadSubAsPicData(opt.__id, container[i].childs);
          else
            this.loadSubData(opt.__id, container[i].childs);
        }
      }
    },

    loadData() {
      let $onNext = (response) => {
        var data = response.data.result;
        this.setDataAsArray(data, this.arr, 1);
      };

      const $onError = () => {};
      const $onComplete = () => {
        this.isOnLoad = false;
      };

      this.isOnLoad = true;
      this.__.httpAction({
        methode: "GET",
        route: "optionsgrp/",
        data: { __useLoop: 0, __useCnd: "0__SPL__"+this.configModule.item },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadSubData(parentID, container) {
      let $onNext = (response) => {
        this.setDataAsArray(response.data.result, container, 3, false, 'opt', parentID);
      };

      const $onError = () => {};
      const $onComplete = () => {
        this.isOnLoad = false;
      };

      this.isOnLoad = true;
      this.__.httpAction({
        methode: "GET",
        route: "options/",
        data: { __useCnd: "2__SPL__" + parentID },
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },

    loadSubAsPicData(parentID, container) {
      let $onNext = (response) => {
        this.setDataAsArray(response.data.result, container, 3, true, 'opt', parentID);
      };

      const $onError = () => {};
      const $onComplete = () => {
        this.isOnLoad = false;
      };

      this.isOnLoad = true;
      this.__.httpAction({
        methode: "GET",
        route: "photos/",
        data: { __useCnd: "2__SPL__" + parentID +'__SPL__OPTION'},
        onNext: $onNext,
        onError: $onError,
        onComplete: $onComplete,
      });
    },
    

    checkFroOptionStaus(arr) {
      arr.map(e => {
        if(e.type === 'cat') {
          if(this.configModule.config.oneSelect && e.value.length === 0) {
            return
          }
          this.catItems.push(e.id)
        }
        if(e.type === 'opt') {
          this.optItems.push(e.id)
          if(e.overlap) {
            this.overlapedItems.push({id: e.id, valueOverlap: e.overlap})
          }
        }
        if(e.value && e.value.length> 0) {
          this.checkFroOptionStaus(e.value)
        }
      })
    },

    init() {
      this.conf=[];
      this.catItems = [];
      this.overlapedItems = [];
      this.optItems = [];
      this.arr = [];
      
      if(this.configModule.dependency[1]) {
        this.conf = JSON.parse(this.configModule.dependency[1]);
        this.checkFroOptionStaus(this.conf)
      }
      this.showEditor = this.configModule.config.oneSelect === true
      this.loadData();
      if(this.configModule.config.oneSelectStoredValue)
       setTimeout(()=>{this.updateOneSelection(this.configModule.config.oneSelectStoredValue)}, 900);
    },

    updateOneSelection(v) {
      //this.oneSelectItems = v
      this.arr.map(e => {
        if(e.checked) {
          const found = v.filter(s => s.id === e.ID)[0]
          if(found.value.cat) {
            e.childs.map( c => {
              c.selected = found.value.cat === c.ID;
              c.childs.map( so => {
              so.selected = found.value.opt === so.ID;
              })
            })
          }
          else if(found.value.opt) {
            e.childs.map( o => {
              o.selected = found.value.opt === o.ID;
            })
          }
        }
      })
    }
  },

  watch: {
    configModule: function () {
      //this.init();
    },
    readyToSave: function () {
      this.$emit("updateBySelectManager", {
        ready: this.readyToSave,
        data: this.oneSelectItems
      });
    }
  },

  mounted() {
   this.init();
  },

  computed: {},
};
</script>
<style lang="scss" scoped>
.SMcontainer {
  background: #0c0e10;
    width: 100%;
    float: left;
    padding: 2%;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 5px;
    border-radius: 10px;
}

.level1 {
  font-size: 16px;
  clear: both;
}
.level1 .b-icon {
  font-size: 16px;
  margin-right: 10px;
}

.level2 {
  margin-left: 20px;
  font-size: 14px;
  clear: both;
}
.level2 .b-icon {
  font-size: 14px;
  margin-right: 10px;
}

.level3 {
  margin-left: 40px;
  font-size: 11px;
}
.level3 .b-icon {
  font-size: 11px;
  margin-right: 10px;
}

.level3 img {
  max-width:80px;
}
.form-control {
  height: 20px !important;
  padding: 5px !important;
  font-size: 11px !important;
}
.picture {
  width: 100px;
  float: left;
}
</style>
