<template>
  <div class="STMcontainer">
    <b-overlay
      :show="isOnLoad"
      variant="dark"
      rounded
      opacity="0.70"
      spinner-variant="primary"
      spinner-small
    >
    <b-modal
      v-model="showUploader"
      scrollable
      :hide-footer="true"
      :hide-header="true"
      :dialog-class="dataUploaderPayload.customClass"
    >
      <galerie-manager
        :__payload="dataUploaderPayload"
      ></galerie-manager>
    </b-modal>
    <b-button
        v-if="allowAddœ()"
        variant="success"
        @click="addEditor = true"
        size="sm"
        style="float: right"
      >
        Ajouter
      </b-button>
    <div class="STMEditor"  v-if="addEditor">
      <div class="p1">
        <base-input
            :placeholder="titleToAdd"
            v-model="titleToAdd"
            >
          </base-input>
        <b-img
                    :src="picToAdd"
                    @click="showUploader = true"
                    fluid
                    style="width: 100%"
                  ></b-img>
        Prix : 
        <b-form-select
            v-model="priceToAdd"
            :options="priceList"
            class="form-control"
          ></b-form-select>

        Galerie : 
        <b-form-select
            v-model="diapTypeToAdd"
            :options="diapList"
            class="form-control"
          ></b-form-select>

      </div>
      <div class="p2">
      <select-manager :configModule="selectorConfig" v-on="{updateBySelectManager: onAllOptionsSelected}"></select-manager></div>
      <b-button
        v-if="allowSave()"
        variant="success"
        @click="AddAction()"
        size="sm"
        style="float: right"
      >
        Enregistrer
      </b-button>
      <b-button
        variant="danger"
        @click="cancel()"
        size="sm"
        style="float: right"
      >
        Annuler
      </b-button>
    </div>

      <div style="clear: both"></div>
      <div class="level1" v-for="(item, index) in Data">
        <b-icon
          v-if="item.act"
          icon="check-square-fill"
          @click="item.act = false; saveAction() "
          style="color: green"
        ></b-icon>
        <b-icon
          v-if="!item.act"
          icon="check-square"
          @click="item.act = true; saveAction() "
        ></b-icon>

        <b-icon
          icon="gear-fill"
          @click="edit(item, index)"

        ></b-icon>

        <b-icon
          icon="trash-fill"
          @click="remouve(item)"
          style="color: red"

        ></b-icon>

        <b-icon
          v-if="index > 0"
          icon="caret-up-fill"
          @click="mouve(item,-1)"
        ></b-icon>

        <b-icon
          v-if="index < Data.length -1"
          icon="caret-down-fill"
          @click="mouve(item)"
        ></b-icon>


        <br>
        <span>{{ item.title }}</span>
        <b-img
                    :src="item.pic"
                    fluid
                    style="width: 100%"
                  ></b-img>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import { computed } from "vue";

export default {
  name: "stock-manager",
  components: {},

  props: {
    configModule: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isOnLoad: false,
      modules: {},
      selectorConfig: {},
      overlapConfig: {},
      warpSelector: [],
      addEditor: false,
      Data: [],
      priceToAdd: '',
      diapTypeToAdd: '',
      titleToAdd: '',
      dataToAdd: '',
      picToAdd: '',
      priceList: [],
      diapList: [],
      showUploader: false,
      idxEdit: -1,
      readyToSave: false,
      dataUploaderPayload: {
        folder: "",
        onFireCB: () => {},
        multiSelection: false,
        customClass: "customPrdEditModal",
      },
    };
  },
  methods: {
    allowAddœ() {
      return !this.addEditor
    },

    allowSave() {
      return this.addEditor && this.readyToSave
    },

    onAllOptionsSelected(e) {
      this.readyToSave = e.ready;
      if(this.readyToSave === true && e.data.length >0) {
        this.dataToAdd = e.data
      }
    },

    AddAction() {
      console.log( this.Data)
      if(this.idxEdit === -1) {
        this.Data.push({
        order: this.Data.length + 1,
        act: true,
        title: this.titleToAdd,
        pic: this.picToAdd,
        diap: this.diapTypeToAdd,
        price: this.priceToAdd,
        opt: this.dataToAdd
      });
      } else {
        this.Data[this.idxEdit] = {
        order: this.Data.length + 1,
        act: true,
        title: this.titleToAdd,
        pic: this.picToAdd,
        diap: this.diapTypeToAdd,
        price: this.priceToAdd,
        opt: this.dataToAdd
      };
      }
      
      this.sortModules();
      
      this.saveAction();
      this.cancel(); 
    },

    cancel() {
      this.titleToAdd = this.picToAdd = this.diapTypeToAdd = this.priceToAdd = this.dataToAdd = '';
      this.selectorConfig.config.oneSelectStoredValue = [];
      this.addEditor = false;
    },

    mouve(itm, sens = 1) {
      itm.order += sens;
      this.sortModules();
      this.saveAction() 
    },

    remouve(itm) {
      this.$bvModal.msgBoxConfirm('Veuillez confirmer la suppression.', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Confirmer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false
      })
        .then(value => {
          if(value) {
            this.Data = this.Data.filter(e => e.order !== itm.order);
            this.saveAction() 
          }
        })
    },

    edit (item, i) {
      this.idxEdit = i;
      this.titleToAdd = item.title;
      this.picToAdd = item.pic;
      this.diapTypeToAdd = item.diap;
      this.priceToAdd = item.price;
      this.dataToAdd = item.opt;
      this.addEditor = true;
      this.selectorConfig.config.oneSelectStoredValue = item.opt;
    },

    sortModules() {
      this.Data = this.Data.sort((a, b) => a.order - b.order);
    },

    saveAction() {
      let $onNext = (response) => {};
      const $onError = () => {};
      const $onComplete = () => {
        this.isOnLoad = false;
      };
      this.isOnLoad = true;
      var diff = {};
      diff[this.configModule.warpConfig.flagDep[0]] = this.configModule.dependency[0];
      diff[this.configModule.warpConfig.flagDep[1]] = JSON.stringify(this.Data);
      this.updateAction({ $onNext, $onError, $onComplete, data: diff });
    },

    updateAction(conf) {
      this.__.httpAction({
        methode: "POST",
        route: this.configModule.warpConfig.route,
        data: conf.data,
        onNext: conf.$onNext,
        onError: conf.$onError,
        onComplete: conf.$onComplete,
      });
    },

    init() {
      this.Data = [];
      if(this.configModule.dependency === undefined)
        return

      console.log((this.configModule.dependency[2] !== 'null') )
      if (this.configModule.dependency[2] !== null) {
        this.Data = JSON.parse(this.configModule.dependency[2]);
      }
      this.overlapConfig = this.configModule.overlapData;
      this.titleToAdd = this.overlapConfig.__title;
      this.picToAdd = this.overlapConfig.__vignette;
      this.priceList = JSON.parse(this.overlapConfig.__pricing).map(e => {
        return {
          text: e.data[1]+' '+e.data[2],
          value: e.data[0]
        }
      });
      this.diapList = JSON.parse(this.overlapConfig.__diaptypes).map(e => {
        return {
          text: e.data[0],
          value: e.data[0]
        }
      })
      this.selectorConfig = {
        item:"PROD_OPTS",
        dependency: this.configModule.dependency,
        config: {
          name: 'Configurateur',
          dispatchRestult: this.warpSelector,
          oneSelect: true,
        }
      }
      this.modules = this.configModule.modules[0];
      this.dataUploaderPayload.folder = "products/prd" + this.configModule.dependency[0];
      this.dataUploaderPayload.onFireCB = (value) => {
        this.picToAdd = value[0];
        this.showUploader = false;
      };
    }
  },

  mounted() {
    setTimeout(()=>{this.init()},1000);
    
  },

  watch: {
    configModule: function () {
      this.init();
    },
  },

  computed: {},
};
</script>
<style lang="scss" scoped>
.STMcontainer {
  background: #0c0e10;
  width: 100%;
  float: left;
  padding: 2%;
  font-size: 18px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.STMEditor {
  background: #151b21;
  width: 100%;
  float: left;
  padding: 2%;
  font-size: 18px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.STMEditor .p1{
  background: #151b21;
  width: 38%;
  float: left;
  font-size: 18px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.STMEditor .p2{
  background: #ffff;
  width: 60%;
  margin-left:2%;
  float: right;
  font-size: 18px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.level1 {
  background: #142537;
  width: 19%;
  margin-left:1%;
  float: left;
  padding: 2%;
  font-size: 13px;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 10px;
}
.level1 .b-icon {
  font-size: 13px;
  margin-right: 10px;
}
.customPrdEditModal {
  max-width: 90%;
  top: 0px;
}

.custom-control-label {
  font-size: 10px !important;
}
</style>
