import {
  BaseInput,
  Card,
  BaseDropdown,
  BaseButton,
  BaseCheckbox,
  PhotosManager,
  OptionSelector,
  Crtl,
  SpecEditor,
  SelectManager,
  TemplateManager,
  StockManager,
  DataManager,
  ProdManager,
  CategoryDropdwon
} from "../components/index";
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(Card.name, Card);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(PhotosManager.name, PhotosManager);
    Vue.component(OptionSelector.name, OptionSelector);
    Vue.component(Crtl.name, Crtl);
    Vue.component(SpecEditor.name, SpecEditor);
    Vue.component(SelectManager.name, SelectManager);
    Vue.component(TemplateManager.name, TemplateManager);
    Vue.component(StockManager.name, StockManager);
    Vue.component(DataManager.name, DataManager);
    Vue.component(ProdManager.name, ProdManager);
    Vue.component(CategoryDropdwon.name, CategoryDropdwon);
    
  },
};

export default GlobalComponents;
